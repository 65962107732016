import { toast } from 'react-toastify';

export const translate = key => {
  return key;
};

export class Messages {
  static error = (message, closetime = 3000) => {
    toast.error(message, { position: toast.POSITION.TOP_CENTER, autoClose: closetime });
  };

  static success = (message, closetime = 2000) => {
    toast.success(message, { position: toast.POSITION.TOP_CENTER, autoClose: closetime });
  };
}

