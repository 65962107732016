import { observable, decorate, computed } from 'mobx';
import { Param as param, Validator } from './';
import moment from 'moment';

class Order {
  constructor(data) {
    this.validate = new Validator();
    this.email = param(data, 'email');
    this.EN_nr = param(data, 'EN_nr');
    this.amount = param(data, 'amount', 1);
    this.date = param(data, 'date', moment().format('DD/MM/YYYY'));
    this.order_time = param(data, 'order_time');
    this.device = param(data, 'device', 'DESKTOP');
    this.state = param(data, 'state', 'UNCONFIRMED');
    this.paid_with = param(data, 'paid_with');
    this.active = param(data, 'active', false);
    this.name = param(data, 'name');
    this.address = param(data, 'address');
    this.telno = param(data, 'telno');
    this.message = param(data, 'message', '');
    this.leftoverStock = param(data, 'leftoverStock');
    this.boxes = param(data, 'boxes');

    this.validation = {
      amount: input => this.validate.input(input, 'Vul een geldige hoeveelheid in'),
      date: input => this.validate.input(input, 'Vul een geldige datum in.'),
      name: input => this.validate.input(input, 'Vul een naam in.'),
      telno: input => this.validate.input(input, 'Vul een telefoonnummer in.'),
    };

    this.validate.initializeErrors(this, this.validation);
  };

  get activeErrors() {
    const errors = Object.keys(this).filter(key => key.includes('error') && this[key].error);
    return errors.map(error => this[error]);
  };

  toggleActive = () => {
    this.active = !this.active;
  };

  toJS = () => {
    return {
      email: this.email,
      EN_nr: this.EN_nr,
      amount: this.amount,
      date: this.date.replace(/-/g, '/'),
      order_time: this.order_time,
      device: this.device,
      state: this.state,
      active: this.active,
      address: this.address,
      telno: this.telno,
      name: this.name,
      message: this.message,
    }
  }
};

decorate(Order, {
  email: observable,
  EN_nr: observable,
  amount: observable,
  date: observable,
  order_time: observable,
  device: observable,
  state: observable,
  active: observable,
  telno: observable,
  address: observable,
  name: observable,
  message: observable,
  activeErrors: computed,
  paid_with: observable,
  leftoverStock: observable,
  boxes: observable
});

export default Order

