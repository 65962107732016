import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class Settings {
  constructor(data) {
    this.validate = new Validator();
    this.id = param(data, 'id');
    this.cherries_form_active = param(data, 'cherries_form_active');

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      id: this.id,
      cherries_form_active: this.cherries_form_active,
    }
  }
};

decorate(Settings, {
  id: observable,
  cherries_form_active: observable,
});

export default Settings

