import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class SortSettings {
  constructor(data) {
    this.validate = new Validator();

    this.sortMethod = param(data, 'sortMethod');
    this.filterOption = param(data, 'filterOption');

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      sortMethod: this.sortMethod,
    }
  }
}

decorate(SortSettings, {
  sortMethod: observable,
  filterOption: observable
});

export default SortSettings;
