import React from 'react';
import { useObserver } from "mobx-react-lite"

const History = () => {
  return useObserver(() => {
    return (
      <main className="cms">
        <h1 className="title">Stock Geschiedenis</h1>
      </main>
    )
  })
}

export default History;

