import React, {useEffect} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Header, Footer } from './global';
import { CmsOverview , CmsOrders, CmsStock, History, Scanner, Home, DayOverview } from './pages';
import { getTokenInfo } from '../utils';
import {useStore} from "../hooks";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getTokenInfo();
  const orderStore = useStore('orderStore');

  useEffect(() => {
    orderStore.selectedOrder = undefined;
  }, [])

  if (token.expiring < Date.now() / 1000) {
    window.location.href = 'https://engelendael.be/cms/login';
  }

  return (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );
}

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>

        <PrivateRoute exact path="/home" component={Home}/>
        <PrivateRoute exact path="/scanner" component={Scanner}/>
        <PrivateRoute exact path="/overview/day" component={DayOverview}/>
        <PrivateRoute exact path="/cms/settings" component={CmsOverview}/>
        <PrivateRoute exact path="/cms/orders" component={CmsOrders}/>
        <PrivateRoute exact path="/cms/history" component={History}/>
        <PrivateRoute exact path="/cms/stock" component={CmsStock}/>

        <Route path="*" component={() => <Redirect to="/home"/> } />
      </Switch>
      <Footer />
    </BrowserRouter>
  )
}

export default Router;
