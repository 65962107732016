import React, {useEffect} from 'react';
import { useObserver } from "mobx-react-lite"
import { Doughnut, Label, DateInput  } from '../elements';
import { useStores } from '../../hooks';
import styles from '../../assets/scss/dayoverview.module.scss';

const DayOverview = () => {
  const { stockStore, orderStore } = useStores(['stockStore', 'orderStore']);

  useEffect(() => {
    stockStore.getStockForOneDay();
  }, []);

  const printOrders = () => {
    orderStore.printOrdersFromDay(stockStore.selectedDayDateConfig.day);
  };

  const displayAllOrders = async () => {
    const { selectedDayDateConfig  } = stockStore;
    const orders = await orderStore.getStockForOneDay(selectedDayDateConfig.day);
    console.log(orders);
  };

  const displayOrdersThatWerentPickedUp = () => {
    console.log('display orders that werent picked up');
  };

  const displayOdersThatAreOnlinePaid = () => {
    console.log('display orders that are online paid');
  };

  return useObserver(() => {
    const { dayOverview, selectedDayDateConfig } = stockStore;
    const {
      totalStock,
      finishedStock,
      amountOfOrders,
      totalOnlinePaidOrders,
      requiredStock,
      ordersLeft,
      amountPaidWithCash,
      amountPaidWithCard,
      amountPaidOnline
    } = dayOverview;

    return (
      <main className={`cms ${styles.page}`}>
        <section>

          <div className={styles.title}>
            <h2 className="title">Overzicht van een dag</h2>
          </div>


          {
            amountOfOrders > 0 && (
              <div className={`${styles.cta} cta`}>
                <div className="btn" style={{marginBottom: '20px'}} onClick={printOrders}>
                  <i className="fas fa-print" />
                  <span>Print QR codes van {selectedDayDateConfig.day}</span>
                </div>
              </div>
            )
          }

          <div className={styles.orderDate}>
            <Label htmlFor="Bekijk het overzicht van:"/>
            <DateInput entity={selectedDayDateConfig} name="day" />
          </div>

          {
            amountOfOrders === 0 && (
              <div className={styles.placeholder}>
                Er zijn geen orders voor de geselecteerde dag
              </div>
            )
          }


          {
            amountOfOrders > 0 && (
              <>
                <h1 className={styles.subtitle} style={{marginTop: '80px'}}>
                  Overzicht kilo's
                </h1>

                <div className={styles.items}>
                  <div className={styles.item}>
                    <strong>Totaal hoeveelheid kilo's</strong>
                    <p>{totalStock}</p>
                  </div>

                  <div className={styles.item}>
                    <strong>Kilo's die reeds zijn afgehaald</strong>
                    <p>{finishedStock}</p>
                  </div>

                  <div className={styles.item}>
                    <strong>Hoeveelheid kilo's die nog nodig zijn</strong>
                    <p>{requiredStock}</p>
                  </div>
                </div>

                <h1 className={styles.subtitle}>
                  Overzicht bestellingen
                </h1>

                <div className={styles.items}>
                  <div className={styles.item} onClick={displayAllOrders}>
                    <strong>Totaal aantal bestellingen</strong>
                    <p>{amountOfOrders}</p>
                  </div>

                  <div className={styles.item} onClick={displayOrdersThatWerentPickedUp}>
                    <strong>Aantal bestellingen die nog afgehaald moeten worden</strong>
                    <p>{ordersLeft}</p>
                  </div>

                  <div className={styles.item} onClick={displayOdersThatAreOnlinePaid}>
                    <strong>Aantal bestellingen die online betaald zijn</strong>
                    <p>{totalOnlinePaidOrders}</p>
                  </div>
                </div>

                <h1 className={styles.subtitle}>
                  Overzicht betaalmethodes
                </h1>

                <div className={styles.items}>
                  <div className={styles.item}>
                    <strong>Aantal kilo's die online betaald zijn</strong>
                    <p>{amountPaidOnline}</p>
                  </div>

                  <div className={styles.item}>
                    <strong>Aantal kilo's die met de kaart betaald zijn</strong>
                    <p>{amountPaidWithCard}</p>
                  </div>

                  <div className={styles.item}>
                    <strong>Aantal kilo's die met cash betaald zijn</strong>
                    <p>{amountPaidWithCash}</p>
                  </div>
                </div>
              </>
            )
          }

        </section>
      </main>
    )
  })
}

export default DayOverview
