import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class Form {
  constructor(data) {
    this.validate = new Validator();
    this.email = param(data, 'email', 'jens@engelendael.be');
    this.telno = param(data, 'telno', '0498789734');
    this.name = param(data, 'name', 'Jens Pauwels');
    this.postalcode = param(data, 'postalcode', '8200');
    this.amount = param(data, 'amount', 5);
    this.date = param(data, 'date');
    this.device = param(data, 'device');

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      email: this.email,
      telno: this.telno,
      address: this.address,
      name: this.name,
      amount: this.amount,
      date: this.date,
      device: this.device,
    }
  }
}

decorate(Form, {
  email: observable,
  telno: observable,
  address: observable,
  name: observable,
  amount: observable,
  date: observable,
  device: observable,
})

export default Form

