import React, { useState, useRef, useEffect } from 'react';
import { observe } from 'mobx';
import ReactToPrint from 'react-to-print';
import { useObserver } from 'mobx-react-lite';

import { useStore, useDebounce } from '../../hooks';
import { Input, List, Error, Textarea, Label, DateInput } from '../elements';
import { SelectedOrder } from '../partials';
import { Messages } from '../I18n';
import { controlOnErrors } from '../../utils';

import styles from '../../assets/scss/cmsOrders.module.scss';

const getColor = (state, paid_with) => {
  switch(state.toLowerCase()) {
    case 'unpaid':
      return 'red';

    case 'paid':
      if (paid_with === 'mollie') {
        return 'orange';
      } else {
        return 'green';
      }

    case 'canceled':
      return 'inherit';

    default:
      return 'inherit';
  }
};

const getTranslation = (state, paid_with) => {
  switch(state.toLowerCase()) {
    case 'unpaid':
      return 'Onbetaald';

    case 'paid':
      if (paid_with === 'mollie') {
        return 'Online betaald'
      } else {
        return 'Opgehaald';
      }

    case 'reserved':
      return 'In wacht'

    case 'canceled':
      return 'Geannuleerd';

    case 'manual':
      return 'Manueel';

    default:
      return state;
  }
};


const Orders = ({ orders, print = false }) => {
  const orderStore = useStore('orderStore');

  const setAsSelectedOrder = order => {
    orderStore.selectedOrder = order;
  };

  return useObserver(() => {
    const { orders } = orderStore;

    return (
      <ul className={`collection ${styles.collection}`} style={print ? {overflowY: 'inherit', maxHeight: 'inherit'} : {}}>
        {
          orders.map(order => (
            <>
              <li onClick={() => setAsSelectedOrder(order)} key={order.EN_nr}>
                <div className={styles.ENnr}>
                  <span style={{color: getColor(order.state, order.paid_with)}}>{order.EN_nr}</span>
                  <span className={styles.state} style={{color: getColor(order.state, order.paid_with)}}>{getTranslation(order.state, order.paid_with)}</span>
                </div>

                <div className={styles.grid}>
                  <span>
                    <i className="fas fa-shopping-basket" />
                    <span>{order.amount}kg</span>
                  </span>

                  <span>
                    <i className="fas fa-envelope" />
                    <span>{order.email}</span>
                  </span>

                  <span>
                    <i className="fas fa-calendar-alt" />
                    <span>{order.date}</span>
                  </span>

                  <span>
                    <i className="fas fa-mobile-alt" />
                    <span>{order.telno}</span>
                  </span>

                  <span>
                    <i className="fas fa-user"/>
                    <span>{order.name}</span>
                  </span>

                  <span>
                    <i className="fas fa-clock" />
                    <span>{order.order_time}</span>
                  </span>
                </div>
              </li>

              {
                order.message !== '' && (
                  <div className={styles.message} onClick={() => setAsSelectedOrder(order)}>
                    <i className="fas fa-comment-alt"></i>
                    <span>
                      <div>
                        {
                          order.message
                        }
                      </div>

                    </span> 
                  </div>
                )
              }
            </> 
          ))
        }
      </ul>
    )
  })
}

const CmsOrders = () => {
  const [isAddOrderOpen, setIsOrderOpen] = useState(false);
  const [extraOptions, setExtraOptions] = useState(false);
  const [searchString, setSearchString] = useState('');
  const orderStore = useStore('orderStore');
  const componentRef = useRef();

  useDebounce(() => {
    if (searchString.length >= 2) {
      orderStore.getAllOrdersWithSearch(searchString);
    }
  }, [searchString]);

  useEffect(() => {
    const dispose = observe(orderStore.sortSettings, 'sortMethod', () => {
      orderStore.getAllOrdersWithSearch(searchString);
    }, true);

    return () => {
      dispose();
    };

  }, [searchString]);

  const changeFilter = ev => {
    setSearchString(ev.target.value);
  };

  const openAddOrderModal = () => {
    setIsOrderOpen(true);
  };

  const addOrder = async () => {
    await controlOnErrors(orderStore.newOrder)
    await orderStore.addOrder();
    setIsOrderOpen(false);
    Messages.success('Order manueel toegevoegd');
  };

  const closeSelectedOrder = () => {
    orderStore.selectedOrder = undefined;
  };

  const saveSelectedOrder = async () => {
    await controlOnErrors(orderStore.selectedOrder);
    await orderStore.saveSelectedOrder();
    orderStore.selectedOrder = undefined;
    Messages.success('De geselecteerde order is opgeslaan');
  };

  const updateResults = () => {
    orderStore.getAllOrdersWithSearch(searchString);
  };

  return useObserver(() => {
    const { orders, newOrder, sortOptions, sortSettings, selectedOrder, dateConfig } = orderStore;

    const SelectedOrderModal = () => ( 
      selectedOrder !== undefined && (
        <>
          <div className={styles.overlay} onClick={closeSelectedOrder}/>
          <div className={styles.modal}>
            <div className="modal-header">
              <span>Order: {selectedOrder.EN_nr}</span>
              <i className="fas fa-times" onClick={closeSelectedOrder}/>
            </div>

            <div className="modal-content">
              <SelectedOrder />
            </div>

            <div className="modal-footer">
              <div className="btn" onClick={saveSelectedOrder}>Sla op</div>
              <div className="btn" onClick={closeSelectedOrder}>Sluit</div>
            </div>
          </div>
        </>
      )
    )

    const AddOrderModal = () => {
      return useObserver(() => {
        return (
          isAddOrderOpen && (
            <>
              <div className={styles.overlay} onClick={() => setIsOrderOpen(false)}/>
              <div className={styles.modal}>
                <div className="modal-header">
                  <span>Voeg een manuele bestelling toe</span>
                  <i className="fas fa-times" onClick={() => setIsOrderOpen(false)}/>
                </div>

                <div className="modal-content">

                  {
                    (parseInt(newOrder.leftoverStock) - parseInt(newOrder.amount)) < 0 && (
                      <p className="error" style={{margin: '30px'}}>
                        Opgelet, door deze bestelling te plaatsen zul je de stock van {newOrder.date} automatisch verhogen met {Math.abs(parseInt(newOrder.leftoverStock) - parseInt(newOrder.amount))}kg.
                      </p>
                    )
                  }

                  <section className={styles.inputs}>
                    <Error activeErrors={newOrder.activeErrors}/>
                    <div className={styles.grid}>
                      <div>
                        <label htmlFor="email">Afhaaldatum en tijdstip<strong>*</strong></label>
                        <div className={styles.grid}>
                          <DateInput entity={newOrder} name="date" type="date" placeholder="Datum"/>
                          <Input entity={newOrder} name="order_time" type="time" placeholder="Tijd"/>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="name">Aantal kg<strong>*</strong></label>
                        <Input entity={newOrder} name="amount" type="number" placeholder="Hoeveelheid"/>
                      </div>
                    </div>


                    <div>
                      <label htmlFor="email">Contactgegevens <strong>*</strong></label>
                      <div className={`${styles.grid} ${styles.combinedInputs}`}>
                        <Input entity={newOrder} name="name" placeholder="Voornaam + naam"/>
                        <Input entity={newOrder} name="telno" placeholder="Telefoonnummer"/>
                        <Input entity={newOrder} name="address" placeholder="Adres"/>
                        <Input entity={newOrder} name="email" placeholder="E-mail"/>
                      </div>
                    </div>

                    <div className={styles.extraInfo}>
                      <label htmlFor="message">Extra mededeling </label>
                      <Textarea entity={newOrder} name="message" placeholder="Vul hier je extra opmerkingen in."/>
                    </div>
                  </section>
                </div>

                <div className="modal-footer">
                  <div className="btn" onClick={() => setIsOrderOpen(false)}>Annuleer</div>
                  <div className="btn" onClick={addOrder}>Voeg bestelling toe</div>
                </div>
              </div>
            </>
          )
        )
      })
    };


    return (
      <main className="cms">

        <div style={{display: 'none'}}>
          <ul className={`collection ${styles.collection}`} style={{maxHeight: 'inherit'}} ref={componentRef} >
            <Orders print/>
          </ul>
        </div>


        <SelectedOrderModal />
        <AddOrderModal />

        <section>
          <h1 className="title">Bestellingsoverzicht</h1>

          <div className={styles.cta}>
            <ReactToPrint
              trigger={() => (
                <div className={`btn ${styles.printerBtn}`}>
                  <i className="fas fa-print"/>
                </div>
              )}
              content={() => componentRef.current}
            />

            <div className="btn" onClick={openAddOrderModal}>
              <i className="fas fa-plus"/>
              <span>Voeg bestelling toe</span>
            </div>
        </div>

        {
          !extraOptions && (
            <p className={styles.extraFilters} style={{margin: '0 0 20px 0'}} onClick={() => setExtraOptions(true)}>Bekijk meer filter instellingen...</p>
          )
        }

        {
          extraOptions && (
            <div className={styles.dates}>
              <div>
                <label htmlFor="from">Van datum:</label>
                <DateInput entity={dateConfig} name="start" afterChange={updateResults}/>
              </div>
              <div>
                <label htmlFor="from">Tot datum:</label>
                <DateInput entity={dateConfig} name="stop" afterChange={updateResults}/>
              </div>
            </div> 
          )
        }

        <div className={styles.filterContainer}>
          <div className={styles.listGrid}>
            <Label htmlFor="Sorteer op"/>
            <List list={sortOptions} entity={sortSettings} name="sortMethod" value="value" text="text" />
          </div>

          <div>
            <Label htmlFor="Filter op"/>
            <div className="search-input">
              <input 
                type="text" 
                placeholder="Typ om een bestelling te zoeken..." 
                style={{backgroundColor: 'rgb(249, 249, 249)'}}
                onChange={changeFilter}
              />
              <i className="fas fa-search" />
            </div>
          </div>
        </div>

        {
          orders.length === 0 && (
            <p className={styles.placeholder}>
              Er zijn geen zoekresultaten die overeen stemmen met de ingegeven zoekterm.
            </p>
          )
        }

        <Orders />
      </section>
    </main>
    )
  })
}

export default CmsOrders;

