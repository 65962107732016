import { observable, decorate } from 'mobx';
import { Day, Settings } from '../components/models';
import { URL, doFetch } from '../utils';

const URLS = {
  GET_ALL_HOURS: `${URL}/admin/engelendael/settings/openings_hours`,
  DELETE_HOUR_FROM_DAY: `${URL}/admin/engelendael/settings/openings_hours/delete`,
  ADD_HOUR_TO_DAY: `${URL}/admin/engelendael/settings/openings_hours/add`,
  SET_STATE_FROM_DAY: `${URL}/admin/engelendael/settings/day/state`,

  GET_SETTINGS: `${URL}/admin/engelendael/settings`,
  UPDATE_FORM_STATE: `${URL}/admin/engelendael/settings/form/state`
};

class SettingsStore {
  constructor() {
    this.days = [];
    this.settings = new Settings();
  };

  getAllDays = async () => {
    const days = await doFetch(URLS.GET_ALL_HOURS, 'GET');
    this.days = days.map(day => new Day(day));
  };

  getSettings = async () => {
    const settings = await doFetch(URLS.GET_SETTINGS, 'GET');
    this.settings = new Settings(settings);
  };

  addHour = async (day) => {
    await doFetch(URLS.ADD_HOUR_TO_DAY, 'POST', {
      day: day.id,
      start: day.new_start,
      stop: day.new_stop
    });

    day.hours.push({
      start: day.new_start,
      stop: day.new_stop 
    });

    day.new_start = '';
    day.new_stop = '';
  };

  updateFormState = state => {
    doFetch(URLS.UPDATE_FORM_STATE, 'PUT', {
      state
    });
  };

  deleteHourFromDay = async (day, start, stop) => {
    await doFetch(URLS.DELETE_HOUR_FROM_DAY, 'DELETE', {
      day: day.id,
      start,
      stop
    });

    day.hours = day.hours.filter(hour => hour.start !== start && hour.stop !== stop);
  };

  setStateFromDay = day => {
    doFetch(URLS.SET_STATE_FROM_DAY, 'PUT', {
      active: day.active,
      day: day.id
    });
  };
};

decorate(SettingsStore, {
  days: observable,
});

export default new SettingsStore();

