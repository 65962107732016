import React, {useState, useEffect} from 'react';
import {useObserver} from "mobx-react-lite"
import moment from 'moment';

import { Input, Textarea, Error, DateInput } from '../elements';
import { Messages } from '../I18n';
import { useStore } from '../../hooks';

import styles from '../../assets/scss/cmsOrders.module.scss';

const SelectedOrder = () => {
  const orderStore = useStore('orderStore');
  const [showWarning, setShowWarning] = useState(false);
  const [tmpObject, setTmpObject] = useState({});

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    () => {
      setShowWarning(false);
    };
  })

  const markAs = (order, state) => {
    switch(state) {
      case 'paidWithCard':
        orderStore.markOrderAs(order, 'paid', 'card')
        Messages.success('order status veranderd.')
        break;

      case 'paidWithCash': 
        orderStore.markOrderAs(order, 'paid', 'cash')
        Messages.success('order status veranderd.')
        break;

      case 'canceled':
        setShowWarning(true);
        setTmpObject({
          order,
          state,
          paidWith: 'canceled',
          dbState: 'canceled'
        });
        // orderStore.markOrderAs(order, 'canceled', 'canceled')
        break;
      case 'mollie':
        orderStore.markOrderAs(order, 'paid', 'mollie');
        Messages.success('order status veranderd.');
        break;
      case 'mollie_finished':
        orderStore.markOrderAs(order, 'paid', 'mollie_finished');
        Messages.success('order status veranderd.');
        break;

      case 'unpaid':
        setShowWarning(true);
        setTmpObject({
          order,
          state,
          paidWith: 'unpaid',
          dbState: 'unpaid'
        });
        // orderStore.markOrderAs(order, 'unpaid', 'unpaid')
        break;
      default:
        console.log(`unknown state: ${state}`);
    }


  };

  const printOrder = async order => {
    try {
      await orderStore.printOrder(order);
      Messages.success('Het order wordt afgeprint.');
    } catch (err) {
      Messages.error('Er heeft zich een fout opgetreden bij het afdrukken van een ticket.')
    }
  };

  const proceed = () => {
    setShowWarning(false);
    const { order, paidWith, dbState } = tmpObject;
    orderStore.markOrderAs(order, dbState, paidWith);
    Messages.success('order status veranderd.')
  };

  const goBack = () => {
    setShowWarning(false);
    setTmpObject({});
  };

  return useObserver(() => {
    const { selectedOrder } = orderStore;

    return (

    <div className={styles.selectedOrder}>

      {
        selectedOrder !== undefined && !showWarning && (
          <>
            <section className={styles.orderInfo}>
              {
                (
                  selectedOrder.date !== moment().format('DD/MM/YYYY') &&
                  (selectedOrder.paid_with === 'mollie' || selectedOrder.paid_with === 'unpaid')
                ) && ( 
                  <div className={styles.warning}>De geselecteerde bestelling is voor {selectedOrder.date}</div>
                )
              }

              {
                (selectedOrder.paid_with === 'unpaid' || selectedOrder.paid_with === 'canceled') && (
                  <>
                    <p className="flex" style={{marginBottom: '10px'}}>
                      <i className="fas fa-hand-paper" style={{marginRight: '10px'}}/>
                      {
                        selectedOrder.state === 'canceled' && (
                          <strong style={{color: 'red'}}>Opgepast! De bestelling is geannuleerd!</strong>
                        )
                      } 

                      {
                        selectedOrder.state !== 'canceled' && (
                          <strong style={{color: 'red'}}>De bestelling is nog niet betaald</strong>
                        )
                      }
                    </p>

                    <span>Bestelling: <strong>{selectedOrder.amount}kg</strong> kersen.</span>
                    <span>Fruitboxen: <strong>{selectedOrder.boxes}</strong></span>
                    <span>Te betalen bedrag: <strong>€{(parseInt(selectedOrder.boxes) * 1) + ( parseInt(selectedOrder.amount * 10) )}</strong></span>
                  </>
                )
              }

              {
                selectedOrder.paid_with !== 'unpaid' && selectedOrder.paid_with !== 'canceled' && (
                  <>

                    

                  {
                    selectedOrder.paid_with === 'cash' && (
                      <p className="flex" style={{marginBottom: '10px'}}>
                        <i className="fas fa-coins" style={{marginRight: '10px'}}/>
                        <strong>De bestelling is betaald met cash</strong>
                      </p>
                    )
                  }

                  {
                    selectedOrder.paid_with === 'card' && (
                      <p className="flex" style={{marginBottom: '10px'}}>
                        <i className="fas fa-credit-card" style={{marginRight: '10px'}}/>
                        <strong>De bestelling is betaald met de kaart</strong>
                      </p>
                    )
                  }

                  {
                    selectedOrder.paid_with === 'mollie' && (
                      <p className="flex" style={{marginBottom: '10px'}}>
                        <i className="fas fa-laptop" style={{marginRight: '10px'}}/>
                        <strong>De bestelling is online betaald</strong>
                      </p>
                    )
                  }

                  {
                    selectedOrder.paid_with === 'mollie_finished' && (
                      <p className="flex" style={{marginBottom: '10px'}}>
                        <i className="fas fa-laptop" style={{marginRight: '10px'}}/>
                        <strong>De bestelling is online betaald en afgehaald</strong>
                      </p>
                    )
                  }

                  <span>Bestelling: <strong>{selectedOrder.amount}kg</strong> kersen.</span>
                  <span>Fruitboxen: <strong>{selectedOrder.boxes}</strong></span>
                  <span>Betaald bedrag: <strong>€{(parseInt(selectedOrder.amount) * 10) + (parseInt(selectedOrder.boxes) * 1)}</strong></span>
                </>
                )
              }
            </section>

            <section className={styles.orderOptions}>
              {
                (selectedOrder.state === 'unpaid' || selectedOrder.state === 'manual' || selectedOrder.state === 'canceled') && (
                  <>
                    <div className="btn" style={{backgroundColor: 'green'}} onClick={() => markAs(selectedOrder, 'paidWithCard')}>
                      <i className="fas fa-credit-card" />
                      <span>Betaald met de kaart</span>
                    </div>
                    <div className="btn" style={{backgroundColor: 'green'}} onClick={() => markAs(selectedOrder, 'paidWithCash')}>
                      <i className="fas fa-coins" />
                      <span>Betaald met cash</span>
                    </div>

                    {
                      selectedOrder.state !== 'canceled' && (
                        <div className="btn" onClick={() => markAs(selectedOrder, 'canceled')}>
                          <i className="fas fa-ban" />
                          <span>Bestelling annuleren</span>
                        </div>
                      )
                    }
                  </>
                )
              }

              {
                selectedOrder.paid_with === 'mollie' && (
                  <div className="btn" style={{backgroundColor: 'green'}} onClick={() => markAs(selectedOrder, 'mollie_finished')}>
                    <i className="fas fa-undo" />
                    <span>Markeren als afgehaald</span>
                  </div>
                )
              }

              {
                selectedOrder.paid_with === 'mollie_finished' && (
                  <div className="btn" style={{backgroundColor: 'grey'}} onClick={() => markAs(selectedOrder, 'mollie')}>
                    <i className="fas fa-undo" />
                    <span>Markeren als niet afgehaald</span>
                  </div>
                )
              }
              {
                selectedOrder.state === 'paid' && (
                  <>
                    <div className="btn" style={{backgroundColor: 'grey'}} onClick={() => markAs(selectedOrder, 'unpaid')}>
                      <i className="fas fa-undo" />
                      <span>Markeren als onbetaald</span>
                    </div>

                    <div className="btn" onClick={() => markAs(selectedOrder, 'canceled')}>
                      <i className="fas fa-ban" />
                      <span>Bestelling annuleren</span>
                    </div>
                  </>
                )
              }

              {/* <div className="btn" style={{backgroundColor: 'grey'}} onClick={() => printOrder(selectedOrder)}> */}
              {/*   <i className="fas fa-print" /> */}
              {/*   <span>Print bon af</span> */}
              {/* </div> */}

              
            </section>

            <section className={styles.inputs} style={{marginBottom: '20px'}}>
              <Error activeErrors={selectedOrder.activeErrors}/>
              <div className={styles.grid} style={{marginBottom: '20px'}}>
                <div>
                  <label htmlFor="email">Afhaaldatum en tijdstip<strong>*</strong></label>
                  <div className={styles.grid}>
                    <DateInput entity={selectedOrder} name="date" type="date" placeholder="Datum"/>
                    <Input entity={selectedOrder} name="order_time" type="time" placeholder="Tijd"/>
                  </div>
                </div>
                <div>
                  <label htmlFor="name">Aantal kg<strong>*</strong></label>
                  <Input entity={selectedOrder} name="amount" type="number" placeholder="Hoeveelheid"/>
                </div>

                <div>
                  <label htmlFor="name">Aantal fruitboxen<strong>*</strong></label>
                  <Input entity={selectedOrder} name="boxes" type="number" placeholder="Fruitboxen"/>
                </div>
              </div>


              <div>
                <label htmlFor="email">Contactgegevens <strong>*</strong></label>
                <div className={`${styles.grid} ${styles.combinedInputs}`}>
                  <Input entity={selectedOrder} name="name" placeholder="Voornaam + naam"/>
                  <Input entity={selectedOrder} name="address" placeholder="Adres"/>
                  <Input entity={selectedOrder} name="email" placeholder="E-mail"/>
                  <Input entity={selectedOrder} name="telno" placeholder="Telefoonnummer" />
                </div>
              </div>

              <div className={styles.extraInfo}>
                <label htmlFor="message">Extra mededeling </label>
                <Textarea entity={selectedOrder} name="message" placeholder="Vul hier je extra opmerkingen in." />
              </div>
            </section>
          </>
        )
      
      }
      {
        showWarning && (
          <div>
            <strong className="error">Opgelet! Ben je zeker dat je de bestelling wenst aan te passen?</strong>

            <div className={styles.warningOptions}>
              <div className={styles.warningOption} onClick={proceed}>
                <i className="fas fa-check" /> 
                <span>Ja</span>
              </div>

              <div className={styles.warningOption} onClick={goBack}>
                <i className="fas fa-hand-paper" /> 
                <span>Nee, ga terug</span>
              </div>
            </div>
          </div>
        )
      }

    </div>
    )
  })
}


export default SelectedOrder;
