import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class Account {
  constructor(data) {
    this.validate = new Validator();
    this.username = param(data, 'username');
    this.password = param(data, 'password');

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      username: this.username,
      password: this.password,
    }
  }
}
decorate(Account, {
  username: observable,
  password: observable,
})

export default Account

