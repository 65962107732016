import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { translate } from '../I18n';
import { setValue, getValue, getErrorMessage, validate } from './IO';

const Textarea = props => { 
  const {
    placeholder = '',
    type = 'text',
    className = '',
    ...rest
  } = props;

  const onChange = ev => {
    setValue(props, ev.target.value);
  };

  const onValidate = ev => {
    validate(props, ev.target.value);
  };

  const getStatus = () => {
    const { message, error } = getErrorMessage(props) || {};

    if (error !== undefined && error) {
      return {
        placeholder: message,
        hasError: true
      }
    } else {
      return {
        placeholder: placeholder !== '' ? translate(placeholder) : placeholder,
        hasError: false
      }
    }
  };

  return useObserver(() => { 
    const { hasError, placeholder } = getStatus();

    return (
      <textarea 
        className={`${className} ${hasError && 'invalid'}`}
        type={type}
        onChange={onChange}
        value={getValue(props)}
        onBlur={onValidate}
        placeholder={placeholder}
        {...rest}
      />
    )
  })
}

export default Textarea;
