import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class StockDay {
  constructor(data) {
    this.validate = new Validator();
    this.id = param(data, 'id');
    this.active = param(data, 'active', false);
    this.date = param(data, 'date');
    this.amount = param(data, 'amount', 0);
    this.ordered_amount = param(data, 'ordered_amount', 0);
    this.day_name = param(data, 'day_name');
    this.day = param(data, 'day');

    this.active = this.active === 0 ? false : true;

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      id: this.id,
      active: this.active,
      date: this.date,
      amount: this.amount,
      ordered_amount: this.ordered_amount,
    }
  }
}

decorate(StockDay, {
  id: observable,
  active: observable,
  date: observable,
  amount: observable,
  ordered_amount: observable,
});

export default StockDay

