import { observable, decorate } from 'mobx';
import { doFetch, URL } from '../utils';
import { Form } from '../components/models';

const URLS = {
  ORDER_CHERRIES: `${URL}/engelendael/order`
}

class FormStore {
  constructor() {
    this.form = new Form();
  };

  confirmOrder = () => {
    return doFetch(URLS.ORDER_CHERRIES, 'POST', this.form.toJS());
  };
};

decorate(FormStore, {
  form: observable
});

export default new FormStore();

