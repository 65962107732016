import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStore, useWidthValidator, useRedirect } from '../../hooks';

import engelendaelLogoDark from '../../assets/images/logo_dark.svg'
import styles from '../../assets/scss/header.module.scss';

const Header = () => {
  const authStore = useStore('authStore');
  const { width } = useWidthValidator();
  const { redirect } = useRedirect();
  const [ headerStatus, setHeaderStatus ] = useState(true);
  const [ isHeaderOpen, setIsHeaderOpen ] = useState(false);
  const { pathname } = useLocation();


  const onScroll = function() {
    if (window.innerWidth > 1024) {
       if (this.scrollY > 500) {
         setHeaderStatus(false);
       } else if (this.scrollY < 500) {
         setHeaderStatus(true);
       }
     } else if (window.innerWidth < 1024) {
      if (this.scrollY > 220) {
        setHeaderStatus(false);
      } else if (this.scrollY < 220) {
        setHeaderStatus(true);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.addEventListener('scroll', onScroll);
    };

  }, []);

  const isActive = path => {
    return path === pathname ? styles.active : undefined;
  };

  const logout = async () => {
    await authStore.logout();
    window.location.href = 'https://engelendael.be/cms/login';
  };

  if (width <= 900) {
    return  (
      <>
        {
          isHeaderOpen && (
            <div className={styles.overlay} onClick={() => setIsHeaderOpen(false)}/>
          )
        }
        <header className={styles.cmsHeader} style={{maxHeight: isHeaderOpen ? 300 : 100}}>
          {
            !isHeaderOpen && (
              <i className={`fas fa-bars ${styles.menu}` } onClick={() => setIsHeaderOpen(true)}/>
            )
          }

          {
            isHeaderOpen && (
              <i className={`fas fa-times ${styles.menu}` } onClick={() => setIsHeaderOpen(false)}/>
            )
          }

          <div className="logo" onClick={() => redirect('/')}>
            <img src={engelendaelLogoDark} alt="Engelendael logo" />
          </div>

          {
            isHeaderOpen && (
              <>
                <ul className={styles.links}>
                  <li className={isActive('/scanner')} onClick={() => setIsHeaderOpen(false)}>
                    <Link to="/scanner">Scanner</Link>
                  </li>
                  <li className={isActive('/cms/settings')} onClick={() => setIsHeaderOpen(false)}>
                    <Link to="/cms/settings">Instellingen</Link>
                  </li>
                  <li className={isActive('/overview/day')} onClick={() => setIsHeaderOpen(false)}>
                    <Link to="/overview/day">Overzicht dag</Link>
                  </li>
                  <li className={isActive('/cms/stock')} onClick={() => setIsHeaderOpen(false)}>
                    <Link to="/cms/stock">Stockbeheer</Link>
                  </li>
                  <li className={isActive('/cms/orders')} onClick={() => setIsHeaderOpen(false)}>
                    <Link to="/cms/orders">Overzicht bestellingen</Link>
                  </li>
                  <li onClick={logout}>
                    Logout
                  </li>
                </ul>  
              </>
            )
          }
        </header>  
      </>
      
    )
  }

  return (
    <header className={styles.cmsHeader} style={{maxHeight: isHeaderOpen ? 300 : 100}}>
      <div className="logo">
        <Link to="/">
          <img src={engelendaelLogoDark} alt="Engelendael logo" />
        </Link>
      </div>
      <ul>
        <li className={isActive('/scanner')}>
          <Link to="/scanner">Scanner</Link>
        </li>
        <li className={isActive('/cms/settings')}>
          <Link to="/cms/settings">Instellingen</Link>
        </li>
        <li className={isActive('/overview/day')}>
          <Link to="/overview/day">Overzicht dag</Link>
        </li>
        <li className={isActive('/cms/stock')}>
          <Link to="/cms/stock">Stockbeheer</Link>
        </li>
        <li className={isActive('/cms/orders')}>
          <Link to="/cms/orders">Overzicht bestellingen</Link>
        </li>
        <li onClick={logout}>
          Logout
        </li>

      </ul>
    </header> 
  )
}

export default Header;
