import React from 'react';
import styles from '../../assets/scss/footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <span>© Hoeve Engelendael 2021</span>
      <span>Bekijk ons <strong>privacy</strong> beleid</span>
    </footer>
  )
}

export default Footer;
