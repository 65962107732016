import React, { Component } from 'react';
import ReactMapboxGl, { Marker } from "react-mapbox-gl";

const Map = ReactMapboxGl({ 
  accessToken: "pk.eyJ1IjoiamVuc3BhdXdlbHMiLCJhIjoiY2phZWFmNnh5MXliajJxbmlmaW9vaGV5YiJ9.9PpdFvYJbD6rXVkPu6F4ag" 
});

export default class MapComponent extends Component{
  constructor(){
    super();

    this.state = {
      zoom: [14.15],
      center: [3.57, 51.272] 
    }
  }

  onToggleHover = (map, cursor) => {
    document.querySelector('.mapboxgl-canvas').style.cursor = cursor;
  }

  render() {
    const { zoom, center } = this.state;

    return (
      <div className="map">
        <Map 
          style="mapbox://styles/mapbox/light-v10"
          center={center} 
          containerStyle={{ height: '350px', width: "100%" }}
          zoom={zoom}
          ref={ref => this.map = ref}
        >
          <Marker
            coordinates={[3.57, 51.272]}
            anchor="bottom"
            onClick={() => console.log('on click')}
          >
            <div className="marker">Hoeve Engelendael</div>
          </Marker>
        </Map>
      </div>
    )
  }
}




