import React, { useState, useEffect } from 'react';
import { useObserver } from "mobx-react-lite"
import QrReader from 'react-qr-reader'

import { useStore } from '../../hooks';
import { SelectedOrder } from '../partials';

import styles from '../../assets/scss/qr.module.scss';

const Scanner = () => {
  const [result, setResult] = useState('');
  const orderStore = useStore('orderStore');

  useEffect(() => {
    return () => {
      orderStore.SelectedOrder = undefined;
    };
  }, []);

  const handleScan = async data => {
    try {
      if (data) {
        const arr = data.replace('[', '').replace(']', '').split(',');

        const object = {
          en: arr[0],
          name: arr[1],
          amount: arr[2],
          order_id: arr[3],
          comment: arr[4],
          payment_status: arr[5]
        };

        if (object.en !== '') {
          await orderStore.getOrderByEnNr(object.en);
        }

        setResult(object);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleError = err => {
    console.error(err)
  };

  return useObserver(() => {
    return (
      <main className="cms">
        <div className={styles.title}>
          <h2 className="title">Ticket scanner</h2>
        </div>

        {
          result !== '' && (
            <div className={styles.orderOverview}>
              <SelectedOrder />
              <div className="btn" onClick={() => setResult('')}>Scan een nieuwe bon</div>
            </div>
          )
        }

        {
          result === '' && (
            <>
              <div className={styles.qr}>
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '100%', margin: '0 auto', maxWidth: '500px' }}
                />
              </div>
            </>
          )
        }
      </main>
    )
  })
}

export default Scanner;
