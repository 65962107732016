import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import uuid from 'uuid/v4';
import { translate } from '../I18n';
import { setValue, getValue, getErrorMessage, validate } from './IO';

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css'
import moment from 'moment';

const TimeInput = props => { 
  const randomId = uuid();
  const {
    placeholder = '',
    type = 'text',
    className = '',
    ...rest
  } = props;

  useEffect(() => {
    const value = getValue(props);
    const element = document.getElementById(randomId);

    flatpickr(element, {
      enableTime: true,
      noCalendar: true,
      disableMobile: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: value,
      onChange: ev => {
        setValue(props, moment(ev[0]).format('HH:mm'));
      }
    });

  }, [])

  const onChange = ev => {
    console.log(ev.target.value);
    setValue(props, ev.target.value);
  };

  const onValidate = ev => {
    validate(props, ev.target.value);
  };

  const getStatus = () => {
    const { error, message } = getErrorMessage(props) || {};

    if (error !== undefined && error) {
      return {
        hasError: true,
        placeholder: translate(message)
      }
    } else {
      return {
        hasError: error !== undefined && error,
        placeholder: placeholder !== '' ? translate(placeholder) : ''
      }
    }
  };

  return useObserver(() => { 
    const { hasError, placeholder } = getStatus();

    return (
      <input 
        id={randomId}
        className={`${className} ${hasError && 'invalid'}`}
        type={type}
        onChange={onChange}
        value={getValue(props)}
        onBlur={onValidate}
        placeholder={placeholder}
        autoComplete="off"
        {...rest}
      />
    )
  })
}

export default TimeInput;

