import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class DayOverview {
  constructor(data) {
    this.validate = new Validator();
    this.totalStock = param(data, 'totalStock');
    this.finishedStock = param(data, 'finishedStock');
    this.requiredStock = param(data, 'requiredStock');
    this.amountOfOrders = param(data, 'amountOfOrders');
    this.totalOnlinePaidOrders = param(data, 'totalOnlinePaidOrders');
    this.amountPaidOnline = param(data, 'amountPaidOnline');
    this.amountPaidWithCash = param(data, 'amountPaidWithCash');
    this.amountPaidWithCard = param(data, 'amountPaidWithCard');
    this.ordersLeft = param(data, 'ordersLeft');

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      totalStock: this.totalStock,
      finishedStock: this.finishedStock,
      requiredStock: this.requiredStock,
      amountOfOrders: this.amountOfOrders,
      totalOnlinePaidOrders: this.totalOnlinePaidOrders,
      ordersLeft: this.ordersLeft,
    }
  }
};

decorate(DayOverview, {
  totalStock: observable,
  finishedStock: observable,
  requiredStock: observable,
  amountOfOrders: observable,
  totalOnlinePaidOrders: observable,
  amountPaidOnline: observable,
  amountPaidWithCard: observable,
  amountPaidWithCash: observable,
  
  ordersLeft: observable,
});

export default DayOverview

