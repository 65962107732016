import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';
import moment from 'moment';

class DateConfig {
  constructor(data) {
    this.validate = new Validator();
    this.start = param(data, 'start', moment(Date.now()).format('DD/MM/YYYY'));
    this.stop = param(data, 'stop', moment(Date.now()).add('w', 2).format('DD/MM/YYYY'));
    this.day = param(data, 'day', moment(Date.now()).format('DD/MM/YYYY'));

    if (data === 'order') {
      this.start = moment(Date.now()).subtract('w', 2).format('DD/MM/YYYY')
    }

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      start: this.start,
      stop: this.stop,
    }
  }
}
decorate(DateConfig, {
  start: observable,
  stop: observable,
  day: observable
})

export default DateConfig

