import { observable, decorate, observe } from 'mobx';
import { StockDay, DateConfig, DayOverview } from '../components/models';
import { doFetch, URL } from '../utils';

const URLS = {
  GET_STOCK_FOR_NEXT_2_WEEKS: `${URL}/admin/engelendael/stock/next_2_weeks`,
  SET_STOCK_STATE: `${URL}/admin/engelendael/stock/state`,
  SET_STOCK_AMOUNT: `${URL}/admin/engelendael/stock/amount`,
  GET_ORDERS_FOR_TODAY: `${URL}/admin/engelendael/orders/day`
};

class StockStore {
  constructor() {
    this.days = [];
    this.interval = undefined;
    this.dayInterval = undefined;
    this.dateConfig = new DateConfig()
    this.selectedDayDateConfig = new DateConfig()
    this.dayOverview = new DayOverview();
    this.ordersForSelectedDay = [];

    observe(this.dateConfig, () => {
      this.initalizeDays();
    });

    observe(this.selectedDayDateConfig, 'day', () => {
      this.getStockForOneDay();
    })
  };


  getStockForOneDay = () => {
    const getStock = async () => {
      const { day } = this.selectedDayDateConfig;

      const result = await doFetch(URLS.GET_ORDERS_FOR_TODAY, 'POST', {
        day
      });

      this.dayOverview = new DayOverview(result);
    };

    getStock();

    clearInterval(this.dayInterval);
    this.dayInterval = setInterval(getStock, 5000);

  };

  initalizeDays = () => {
    const getStock = async () => {
      const { start, stop } = this.dateConfig.toJS();

      const stock = await doFetch(URLS.GET_STOCK_FOR_NEXT_2_WEEKS, 'POST', {
        start, 
        stop
      });

      this.days = stock.map(data => new StockDay(data));
    };

    getStock();

    clearInterval(this.interval);
    this.interval = setInterval(getStock, 5000);
  };

  stopInterval = () => {
    clearInterval(this.interval);
  };

  setStockState = async day => {
    const { date, active } = day;

    await doFetch(URLS.SET_STOCK_STATE, 'PUT', {
      active,
      date
    });
  };

  setStockAmount = async day => {
    const { date, amount } = day;

    await doFetch(URLS.SET_STOCK_AMOUNT, 'PUT', {
      amount,
      date
    });
  };

};

decorate(StockStore, {
  days: observable,
  dateConfig: observable,
  selectedDayDateConfig: observable,
  dayOverview: observable
});

export default new StockStore();

