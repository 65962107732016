import modalStore from './stores/modalStore';
import jwtdecoder from 'jwt-decode';

export const URL = '';

export const debounceEvent = (callback, time = 250, interval) => 
  (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };

export const openModal = modalName => {
  modalStore.openModal(modalName);
};

export const closeModal = modalName => {
  modalStore.closeModal(modalName);
};

export const getTokenInfo = () => {
  const value = "; " + document.cookie;
  const parts = value.split('; engelendael_token=');
  try {
    if (parts.length === 2) { 
      return jwtdecoder(parts.pop().split(";").shift())
    } else {
      window.location.href = 'https://engelendael.be/cms/login';
    };

  } catch (e) {
    window.location.href = 'http://engelendael.be/cms/login';
  };
};

export const doFetch = async (url, method, body) => {
  const options = {
    method,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, options);
  const textResponse = await response.text();

  try {
    const json = JSON.parse(textResponse);

    if (response.status === 401 && json.redirect) {
      window.location.href = 'https://engelendael.be/cms/login';
    }

    return json;
  } catch (err) {
    console.log(err);
  }
};

export const handleError = (error, onServerError) => {
  if (error?.constructor?.name === 'ErrorHandler') {
    onServerError(error.status);
  }
};

const asyncForEach = async function (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  };
};


export const controlOnErrors = async (entity, validation) => {
  const keys = Object.keys(validation !== undefined ? validation : entity.validation);
  const errors = [];
  let hasErrors = false;

  await asyncForEach(keys, async (key) => {
    const valueToCheck = key.includes('.')
      ? key.split('.').reduce((obj, child) => obj[child], entity)
      : entity[key]


    const result = (validation !== undefined)
      ? await validation[key](valueToCheck)
      : await entity.validation[key](valueToCheck);


    entity[`error${key.replace(/\./g, '')}`] = result;

    if (result.error) {
      errors.push({ key, error: result.message });
      hasErrors = true;
    }
  });

  return new Promise((resolve, reject) => {
    if (hasErrors) {
      reject(errors);
    } else {
      resolve(errors);
    }
  });
};
