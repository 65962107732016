import { observable, decorate, observe } from 'mobx';
import { Order, SortSettings, DateConfig } from '../components/models';
import { URL, doFetch } from '../utils';


const URLS = {
  GET_ALL_ORDERS: `${URL}/admin/engelendael/orders/all`,
  GET_ALL_ORDERS_FOR_DAY: `${URL}/admin/engelendael/orders/day`,
  GET_ALL_ORDERS_WITH_SEARCH: `${URL}/admin/engelendael/orders/search`,
  UPDATE_ORDER_STATE: `${URL}/admin/engelendael/orders/state`,
  ORDER_CHERRIES: `${URL}/engelendael/order`,
  REFUND_ORDERS: `${URL}/admin/engelendael/order/refund`,
  PRINT_ORDER: `${URL}/admin/engelendael/order/print`,
  PRINT_ORDERS_FROM_DAY: `${URL}/admin/engelendael/orders/print`, 
  GET_ORDER_BY_EN_NR: ENnr => `${URL}/admin/engelendael/order/${ENnr}`,
  SAVE_SELECTED_ORDER: `${URL}/admin/engelendael/order`,
  GET_STOCK_FOR_DAY: `${URL}/admin/engelendael/orders/day/stock`
};

class OrderStore {
  constructor() {
    this.orders = [];
    this.ordersToRender = [];
    this.newOrder = new Order();
    this.selectedOrder = undefined;
    this.sortSettings = new SortSettings();
    this.dateConfig = new DateConfig('order');
    this.interval = undefined;

    this.sortOptions = [
      {
        text: 'Hoeveelheid',
        value: 'amount',
      },
      {
        text: 'Datum',
        value: 'date',
      },
      {
        text: 'EN nummer oplopend',
        value: 'en_desc'
      },
      {
        text: 'EN nummer aflopend',
        value: 'en_asc'
      }
    ];

    observe(this.newOrder, 'date', async ({newValue}) => {
      const res = await doFetch(URLS.GET_STOCK_FOR_DAY, 'POST', {day: newValue});
      this.newOrder.leftoverStock = res.leftoverStock;
    }, true);
  };

  printOrdersFromDay = day => {
    return doFetch(URLS.PRINT_ORDERS_FROM_DAY, 'POST', {
      day
    });
  };

  printOrder = order => {
    return doFetch(URLS.PRINT_ORDER, 'POST', {
      EN_nr: order.EN_nr
    });
  };

  refundOrder = async () => {
    await doFetch(URLS.REFUND_ORDERS, 'POST', {
      orderId: this.selectedOrder.id
    });
  };

  addOrder = async () => {
    const res = await doFetch(URLS.ORDER_CHERRIES, 'POST', {
      ...this.newOrder.toJS(),
      payment_method: 'manual',
    });

    this.orders.unshift(new Order({ ...this.newOrder.toJS(), state: 'manual', EN_nr: res.en }));
    this.newOrder = new Order();

    observe(this.newOrder, 'date', async ({newValue}) => {
      const res = await doFetch(URLS.GET_STOCK_FOR_DAY, 'POST', {day: newValue});
      this.newOrder.leftoverStock = res.leftoverStock;
    }, true);

    return res;
  };

  getAllOrders = async () => {
    const orders = await doFetch(URLS.GET_ALL_ORDERS, 'GET');
    this.orders = orders.map(order => new Order(order));
  };

  getAllOrdersForDay = async (day, filters = []) => {
    const orders = await doFetch(URLS.GET_ALL_ORDERS_FOR_DAY(), 'POST', {
      day,
      filters
    });

    return orders.map(order => new Order(order));
  };

  getSortRules = () => {
    switch(this.sortSettings.sortMethod) {
      case 'amount':
        return [
          {
            type: 'amount',
            sort: 'ASC',
          },
        ]

      case 'date':
        return [
          {
            type: 'sort_date',
            sort: 'ASC'
          }
        ]

      case 'en_desc':
        return [
          {
            type: 'EN_nr',
            sort: 'desc'
          }
        ]

      case 'en_asc':
        return [
          {
            type: 'EN_nr',
            sort: 'ASC'
          }
        ]

      default:
        return [];
    }
  };

  getAllOrdersWithSearch = async searchstring => {

    const search = async () => {
      const orders = await doFetch(URLS.GET_ALL_ORDERS_WITH_SEARCH, 'POST', {
        search_string: searchstring,
        sort_rules: this.getSortRules(),
        ...this.dateConfig.toJS()
      });

      this.orders = orders.map(order => new Order(order));
    }

    search();

    // clearInterval(this.interval);
    // this.interval = setInterval(search, 5000);
  };

  setState = async state => {
    const activeOrders = this.orders.filter(order => order.active);

    await doFetch(URLS.UPDATE_ORDER_STATE, 'PUT', {
      orders: activeOrders.map(order => order.EN_nr),
      state
    });

    activeOrders.forEach(order => {
      order.state = state;
      order.active = false;
    });
  };


  markOrderAs = async (order, state, paidWith) => {
    await doFetch(URLS.UPDATE_ORDER_STATE, 'PUT', {
      orders: [order.EN_nr],
      state,
      paidWith
    })

    order.paid_with = paidWith;
    order.state = state;
  };

  getOrderByEnNr = async ENnr => {
    const order = await doFetch(URLS.GET_ORDER_BY_EN_NR(ENnr), 'GET');
    this.selectedOrder = new Order(order);
  };

  saveSelectedOrder = () => {
    return doFetch(URLS.SAVE_SELECTED_ORDER, 'POST', this.selectedOrder.toJS());
  };
};

decorate(OrderStore, {
  orders: observable,
  newOrder: observable,
  sortMethod: observable,
  selectedOrder: observable,
  dateConfig: observable
});

export default new OrderStore();

