import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const CustomDoughnut = ({ amount, ordered_amount, reverseColors = false }) => {
  const data = {
    labels: [
      'Totale hoeveelheid',
      'Bestelde kersen',
    ],
    datasets: [{
      data: [(amount - ordered_amount), ordered_amount],
      backgroundColor: [
        '#2FE51F',
        '#E51F1F',
      ],
    }]
  }

  if (reverseColors) {
    data.datasets = [ {
      data: [(amount - ordered_amount), ordered_amount],
      backgroundColor: [
        '#E51F1F',
        '#2FE51F',
      ]}
    ]
  }

  const options = {
    cutoutPercentage: 85,
    legend: {
      display: false
    },
  }

  return (
    <Doughnut data={data} options={options} />
  )
}

export default CustomDoughnut;
