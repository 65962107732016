import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { setValue, getValue, getErrorMessage, validate } from './IO';

const Input = props => { 
  const {
    placeholder = '',
    type = 'text',
    className = '',
    disableAutoFill = undefined,
    ...rest
  } = props;

  const onChange = ev => {
    setValue(props, ev.target.value);
  };

  const onValidate = ev => {
    validate(props, ev.target.value);
  };

  const getStatus = () => {
    const { error, message } = getErrorMessage(props) || {};

    if (error !== undefined && error) {
      return {
        hasError: true,
        placeholder: message
      }
    } else {
      return {
        hasError: false,
        placeholder: placeholder
      }
    }
  };

  return useObserver(() => { 
    const { hasError, placeholder } = getStatus();

    return (
      <input 
        className={`${className} ${hasError && 'invalid'}`}
        type={type}
        onChange={onChange}
        value={getValue(props)}
        onBlur={onValidate}
        placeholder={placeholder}
        autoComplete={disableAutoFill && 'none'}
        {...rest}
      />
    )
  })
}

export default Input;
